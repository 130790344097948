import { fbAuth, firebase } from './Firebase';
import Api from './Api';
async function facebookLogin() {
  const provider = new firebase.auth.FacebookAuthProvider();

  provider.addScope('email');
  // provider.addScope("public_profile");

  const response = await fbAuth.signInWithPopup(provider).then(
    async result => {
      if (result?.user) {
        const user = {
          uid: result.user?.uid,
          name:
            result.additionalUserInfo?.profile?.name ||
            result.user?.displayName,
          photoURL:
            result.additionalUserInfo?.profile?.picture?.data?.url ||
            result.user?.photoURL,
          email:
            result.additionalUserInfo?.profile?.email || result.user?.email,
        };

        try {
          const data = await Api.post('/user/facebook', {
            name: user.name,
            email: user.email,
            firebaseUserUID: user.uid,
            provider: 'facebook',
          });

          if (Reflect.has(data, 'email')) return true;
        } catch (error) {
          throw error;
        }
      }
    },
    error => {
      throw error;
    }
  );
  return response;
}

async function googleLogin() {
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('email');

  const response = await fbAuth.signInWithPopup(provider).then(
    async result => {
      const user = {
        uid: result.user?.uid,
        name: result.user?.displayName,
        photoURL: result.user?.photoURL,
        // @ts-ignore
        email: result.additionalUserInfo?.profile?.email,
      };

      try {
        const data = await Api.post('/user/google', {
          name: user.name,
          email: user.email,
          firebaseUserUID: user.uid,
          provider: 'google',
          photoURL: user.photoURL,
        });

        if (Reflect.has(data, 'email')) return true;
      } catch (error) {
        throw error;
      }
    },
    error => {
      throw error;
    }
  );

  return response;
}

export { facebookLogin, googleLogin };
