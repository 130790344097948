import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import React, { useContext, useEffect } from 'react';
import LinkContext from '../context/NewLink';

export function LinkForm() {
  const { setDescription, updateLink, setLink, link, description } =
    useContext(LinkContext);

  useEffect(() => {
    if (!isEmpty(updateLink)) {
      setLink(updateLink.full);
      setDescription(updateLink.description);
    }
  }, [updateLink]);

  return (
    <>
      <FormControl mb={1}>
        <FormLabel>Link a ser encurtado</FormLabel>
        <Input
          onChange={e => setLink(e.target.value)}
          value={link}
          placeholder="https://google.com"
        />
      </FormControl>
      <FormControl mb={1}>
        <FormLabel>Descrição</FormLabel>
        <Input
          onChange={e => setDescription(e.target.value)}
          value={description}
          placeholder="Criado para campanha de moda"
        />
      </FormControl>
    </>
  );
}
