import React, { createContext, useState, useEffect, Fragment } from 'react';
import { facebookLogin, googleLogin } from '../services/Auth';
import { fbAuth } from '../services/Firebase';
import { isEmpty } from 'lodash';
// import UserStorage from '../../helpers/user-storage';
// import { firebaseErrors } from 'helpers/firebase-errors';
// import { useSnackbar } from 'react-simple-snackbar';
// import Api from '../services/Api';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  //   const [openSnackbar] = useSnackbar();
  const [authLoading, setAuthLoading] = useState(false);
  const [error, setError] = useState({});
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fbAuth.onAuthStateChanged(async user => {
      if (isEmpty(user)) {
        setLoading(false);
        fbAuth.signOut();
        navigate('/login', { replace: true });
        return;
      }

      // navigate('/', { replace: true });
      setUser(user);
      setLoading(false);
    });
  }, [navigate]);

  async function signInWithEmailAndPassword(email, password) {
    try {
      setAuthLoading(true);

      const {
        user: { displayName, email: userEmail, photoURL },
      } = await fbAuth.signInWithEmailAndPassword(email, password);

      //@ts-ignore
      const token = await fbAuth?.user.getIdToken(true);

      const userData = {
        name: displayName,
        email: userEmail,
        photoURL: photoURL,
        provider: 'email',
        token,
      };

      if (token) {
        //@ts-ignore
        setUser({ ...userData, token });

        setAuthLoading(false);
      }
    } catch (e) {
      //   const error = firebaseErrors(e.code);
      setError({ e });
      setAuthLoading(false);
    }
  }

  // async function register(input) {
  //   const { email, password, name, phone } = input;
  //   setAuthLoading(true);
  //   try {
  //     // Create a new user on firebase
  //     const { user } = await fbAuth.createUserWithEmailAndPassword(
  //       email,
  //       password
  //     );

  //     // get the instance and update the profile name
  //     await user.updateProfile({
  //       displayName: name,
  //     });

  //     // remove password
  //     const userData = {
  //       name,
  //       phone,
  //       firebaseUserUID: user.uid,
  //       email,
  //     };

  //     const data = await Api.post('/customer', { ...userData });

  //     if (data?.status === 'success') {
  //       const logIn = await fbAuth.signInWithEmailAndPassword(email, password);
  //       const token = await logIn.user.getIdToken(true);
  //       setAuthLoading(false);
  //       return data;
  //     }
  //   } catch (error) {
  //     //   openSnackbar(firebaseErrors(error.message));
  //     setAuthLoading(false);
  //   }
  // }

  async function signInWithGoogle() {
    try {
      setAuthLoading(true);

      const data = await googleLogin();

      if (data) {
        setAuthLoading(false);
        return true;
      }
    } catch (error) {
      setAuthLoading(false);
      throw error;
    }
  }

  async function signInWithFacebook() {
    try {
      setAuthLoading(true);
      // @ts-ignore
      const data = await facebookLogin();

      if (data) {
        setAuthLoading(false);
        return true;
      }
    } catch (error) {
      setAuthLoading(false);
      throw error;
    }
  }

  async function signOut() {
    await fbAuth.signOut();
  }

  if (loading && isEmpty(user)) return null;

  return (
    <Fragment>
      <AuthContext.Provider
        value={{
          isLoggedIn: !isEmpty(user),
          authLoading,
          loading,
          error,
          setAuthLoading,
          signOut,
          signInWithEmailAndPassword,
          // register,
          signInWithGoogle,
          signInWithFacebook,
          user,
        }}
      >
        {children}
      </AuthContext.Provider>
    </Fragment>
  );
};

export default AuthContext;
