import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  Stack,
  Link,
  Button,
  Heading,
  Text,
  useColorModeValue,
  Divider,
} from '@chakra-ui/react';
import { useContext, useState } from 'react';
import { FaFacebook, FaGoogle } from 'react-icons/fa';
import LoginNav from '../components/LoginNav';
import { fbAuth } from '../services/Firebase';
import AuthContext from '../context/Auth';
import { useNavigate } from 'react-router-dom';
import { isEmpty } from 'lodash';

export default function Login({ props }) {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const {
    signInWithGoogle,
    signInWithFacebook,
    signInWithEmailAndPassword,
    authLoading,
  } = useContext(AuthContext);

  async function handleLogin(e) {
    e.preventDefault();

    await signInWithEmailAndPassword(email, password);

    if (!isEmpty(fbAuth.currentUser)) {
      navigate('/', { replace: true });
    }
  }

  async function registerWithSocial(provider) {
    try {
      const response =
        provider === 'google'
          ? await signInWithGoogle()
          : await signInWithFacebook();

      if (response) {
        navigate('/', { replace: true });
      }
    } catch (error) {
      // openSnackbar(firebaseErrors(error.code));
      // setAuthLoading(false);
    }
  }

  return (
    <>
      <LoginNav />
      <Flex
        minH={'calc(100vh - 80px)'}
        align={'center'}
        justify={'center'}
        bg={useColorModeValue('gray.50', 'gray.800')}
      >
        <Stack spacing={8} mx={'auto'} maxW={'lg'} py={12} px={6}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Entre com sua conta</Heading>
            <Text fontSize={'lg'} color={'gray.600'}>
              para curtir todas nossas funcionalidades✌️
            </Text>
          </Stack>

          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}
          >
            <Stack spacing={4}>
              <Stack spacing={6} direction={['column', 'row']}>
                <Button
                  onClick={() => registerWithSocial('google')}
                  leftIcon={<FaGoogle />}
                  bg={'red.400'}
                  disabled={authLoading}
                  color={'white'}
                  w="full"
                  _hover={{
                    bg: 'red.500',
                  }}
                >
                  Google
                </Button>
                <Button
                  leftIcon={<FaFacebook />}
                  onClick={() => registerWithSocial('facebook')}
                  bg={'blue.400'}
                  color={'white'}
                  disabled={authLoading}
                  w="full"
                  _hover={{
                    bg: 'blue.500',
                  }}
                >
                  Facebook
                </Button>
              </Stack>
              <Divider />
              <FormControl onSubmit={handleLogin} id="email">
                <FormLabel>Email</FormLabel>
                <Input
                  disabled={authLoading}
                  onChange={({ target }) => setEmail(target.value)}
                  type="email"
                  value={email}
                />
              </FormControl>
              <FormControl id="password">
                <FormLabel>Senha</FormLabel>
                <Input
                  disabled={authLoading}
                  type="password"
                  value={password}
                  onChange={({ target }) => setPassword(target.value)}
                />
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={{ base: 'column', sm: 'row' }}
                  align={'start'}
                  justify={'space-between'}
                >
                  <Checkbox>Lembrar</Checkbox>
                  <Link color={'blue.400'}>Esqueceu sua senha?</Link>
                </Stack>
                <Button
                  onClick={handleLogin}
                  bg={'blue.400'}
                  color={'white'}
                  disabled={authLoading}
                  _hover={{
                    bg: 'blue.500',
                  }}
                >
                  {authLoading ? 'Entrando...' : 'Entrar'}
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Flex>
    </>
  );
}
