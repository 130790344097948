import { isEmpty } from 'lodash';
import React, { createContext, Fragment, useState } from 'react';
import Api from '../services/Api';

const LinkContext = createContext();

export const LinkProvider = ({ children }) => {
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [updateLink, setUpdateLink] = useState();

  function resetFields() {
    setLink('');
    setDescription('');
    setUpdateLink({});
  }

  async function handleLink(e) {
    e.preventDefault();
    setLoading(true);

    try {
      !isEmpty(updateLink)
        ? await Api.put(
            `/link/${updateLink?._id}`,
            { description, full: link },
            true
          )
        : await Api.post('/link/create', { description, full: link }, true);
      setLoading(false);
      resetFields();
    } catch (error) {
      resetFields();
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <LinkContext.Provider
        value={{
          setDescription,
          setLink,
          isLoading,
          handleLink,
          link,
          description,
          setUpdateLink,
          updateLink,
          resetFields,
        }}
      >
        {children}
      </LinkContext.Provider>
    </Fragment>
  );
};

export default LinkContext;
