import { LinkIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';
import { FiLink2, FiMousePointer } from 'react-icons/fi';
import useFetch from '../services/hooks/useFetch';

function StatsCard(props) {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 5 }}
      py={'5'}
      width={'100%'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}
    >
      <Flex justifyContent={'space-between'}>
        <Box>
          <StatLabel fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box
          my={'auto'}
          color={useColorModeValue('gray.800', 'gray.200')}
          alignContent={'center'}
        >
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
}

export default function Statistics() {
  const { data, loading } = useFetch('/statistic');
  return (
    <Box pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard
          title={'Links Criados'}
          stat={(loading ? '...' : data?.data?.links) || 0}
          icon={<LinkIcon fontSize={30} />}
        />
        <StatsCard
          title={'Cliques'}
          stat={(loading ? '...' : data?.data?.clicks) || 0}
          icon={<FiMousePointer size={30} />}
        />
        <StatsCard
          title={'Links Adicionados no Mês'}
          stat={(loading ? '...' : 0) || 0}
          icon={<FiLink2 size={30} />}
        />
      </SimpleGrid>
    </Box>
  );
}
