import React from 'react';
import { Stack } from '@chakra-ui/react';

const Card = ({ children, onClick }) => {
  return (
    <Stack
      style={{ cursor: 'pointer' }}
      onClick={onClick}
      h={'100%'}
      m={2}
      bg="white"
      p="8"
      boxShadow="lg"
      borderRadius="sm"
    >
      {children}
    </Stack>
  );
};

export default Card;
