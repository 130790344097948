import {
  Button,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import LinkContext from '../context/NewLink';

export function LinkModal({
  buttonText,
  leftIcon,
  variant,
  modalHeaderText,
  onOpen,
  onClose,
  isOpen,
  children,
  onClick,
}) {
  const { handleLink, setUpdateLink, isLoading, resetFields } =
    useContext(LinkContext);

  function handleClose() {
    onClose();
    setUpdateLink('');
    resetFields();
    onClose();
  }

  async function handleSubmit(e) {
    await handleLink(e);
    handleClose();
  }

  return (
    <>
      <Button
        leftIcon={leftIcon}
        colorScheme="purple"
        variant={variant}
        onClick={onClick}
        size={'sm'}
      >
        {buttonText}
      </Button>

      <Modal size={'3xl'} isOpen={isOpen} onClose={handleClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{modalHeaderText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>

          <ModalFooter>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleSubmit}
              colorScheme="purple"
              mr={3}
            >
              Salvar
            </Button>
            <Button
              disabled={isLoading}
              isLoading={isLoading}
              onClick={handleClose}
              variant="outline"
            >
              Cancelar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
