import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import LinkMeta from '../components/LinkMeta';
import Links from '../components/Links';

function Link() {
  const [linkId, setLinkId] = useState();

  return (
    <Flex flexWrap={'wrap'}>
      <Box style={{ height: '80vh', overflow: 'auto' }}>
        <Links setLinkId={id => setLinkId(id)} />
      </Box>
      {linkId && (
        <Box flex={1} overflow={'hidden'}>
          <LinkMeta linkId={linkId} />
        </Box>
      )}
    </Flex>
  );
}

export default Link;
