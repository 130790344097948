import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Spinner,
  useToast,
  Link,
} from '@chakra-ui/react';
import SidebarWithHeader from '../components/Sidebar';
import Card from '../components/Card';
import Api from '../services/Api';

const API_URL = '/apiKey';
const REVOKE_URL = '/apiKey/revoke';

const ApiKey = () => {
  const [apiKey, setApiKey] = useState('');
  const [loading, setLoading] = useState(true);
  const toast = useToast();

  useEffect(() => {
    loadApiKey();
  }, []);

  const loadApiKey = async () => {
    try {
      const response = await Api.get(API_URL, {}, true);
      setApiKey(response.apiKey);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Erro ao carregar chave de API:', error);
    }
  };

  const handleGenerateApiKey = async () => {
    if (apiKey) {
      toast({
        title: 'Você já possui uma API Key',
        status: 'info',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const response = await Api.post(API_URL, {}, true);
      setApiKey(response.apiKey);
    } catch (error) {
      console.error('Erro ao gerar chave de API:', error);
    }
  };

  const handleRevokeApiKey = async () => {
    try {
      await Api.post(REVOKE_URL, {}, true);
      setApiKey('');
      loadApiKey();
    } catch (error) {
      console.error('Erro ao revogar chave de API:', error);
    }
  };

  const handleCopyToClipboard = async text => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Texto copiado para a área de transferência:', text);
      toast({
        title: 'Copiado para a área de transferência',
        status: 'success',
        position: 'top-right',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erro ao copiar para a área de transferência:', error);
    }
  };

  return (
    <SidebarWithHeader>
      <Card>
        <Box p={4}>
          <Box mb={4}>
            <p>
              Suas chaves secretas de API estão listadas abaixo. Observe que não
              exibimos suas chaves secretas de API novamente após você gerá-las.
            </p>
            <p>
              Não compartilhe sua chave de API com outras pessoas nem a exponha
              no navegador ou em outro código do lado do cliente. Para proteger
              a segurança da sua conta, o <b>encurtalinks</b> também pode
              desabilitar automaticamente qualquer chave de API que tenhamos
              vazado publicamente.
            </p>
            <p>
              Confira nossa{' '}
              <Link isExternal href="https://api-hbzynw55ya-ue.a.run.app/docs">
                documentação de API
              </Link>
            </p>
          </Box>
          {loading ? (
            <Spinner size="xl" color="purple.500" />
          ) : (
            <>
              {apiKey && (
                <Box mb={4}>
                  <Table variant="simple">
                    <Thead>
                      <Tr>
                        <Th>API Key</Th>
                        <Th>Ações</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td>
                          {apiKey.substring(0, 10)}...
                          {apiKey.substring(apiKey.length - 10)}
                          <Button
                            ml={2}
                            colorScheme="purple"
                            size="xs"
                            onClick={() => handleCopyToClipboard(apiKey)}
                          >
                            Copiar
                          </Button>
                        </Td>
                        <Td>
                          <Button
                            colorScheme="red"
                            size="xs"
                            onClick={handleRevokeApiKey}
                          >
                            Revogar
                          </Button>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </Box>
              )}
              {!apiKey && (
                <Button
                  colorScheme="purple"
                  size="sm"
                  onClick={handleGenerateApiKey}
                >
                  Criar API Key
                </Button>
              )}
            </>
          )}
        </Box>
      </Card>
    </SidebarWithHeader>
  );
};

export default ApiKey;
