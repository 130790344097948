import { stringify } from 'querystring';
import { fbAuth } from './Firebase';

export const BASE_URL = process.env.REACT_APP_API_URL;

export default class Api {
  static async fetchResource(
    method = 'GET',
    endpoint = '/',
    params = {},
    withAuth = false,
    isFormData = false
  ) {
    const headers = new Headers();

    let uri = BASE_URL + endpoint;
    let response;

    if (isFormData) {
      headers.set('Accept', 'application/json');
      // headers.set("Content-Type", "application/x-www-form-urlencoded");
    } else {
      headers.set('Content-Type', 'application/json');
      headers.set('Accept', 'application/json');
    }

    if (withAuth) {
      const token = await fbAuth.currentUser.getIdToken(true);
      headers.set('Authorization', `Bearer ${token}`);
    }

    if (method === 'GET' || method === 'DELETE') {
      uri += '?' + stringify(params);

      response = await fetch(uri, { method, headers });
    } else {
      response = await fetch(uri, {
        method,
        headers,
        body: isFormData ? params : JSON.stringify(params),
      });
    }

    if (response.status === 204) {
      return true;
    }

    if (response.ok) {
      return await response.json();
    }

    if (!response.ok) {
      const error = await response.json();
      throw error;
    }
  }

  static async get(endpoint, params = {}, withAuth = false) {
    return Api.fetchResource('GET', endpoint, params, withAuth);
  }

  static async post(endpoint, body = {}, withAuth = false, isFormData = false) {
    return Api.fetchResource('POST', endpoint, body, withAuth, isFormData);
  }

  static async put(endpoint, body = {}, withAuth = false) {
    return Api.fetchResource('PUT', endpoint, body, withAuth);
  }

  static async delete(endpoint, withAuth = false) {
    return Api.fetchResource('DELETE', endpoint, {}, withAuth);
  }
}
