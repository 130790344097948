import moment from 'moment';
import React from 'react';
import { Chart } from 'react-google-charts';

export const options = {
  chart: {
    hAxis: {
      baselineColor: '#805AD5',
    },
    colors: ['#805AD5'],
    legend: { position: 'top', textStyle: { color: 'blue', fontSize: 16 } },
    vAxis: {
      format: false,
    },
  },
};

export function LinkChart({ linkHistory }) {
  const data = linkHistory.map(t => [
    moment(t.createdAt).format('LL'),
    t.count,
  ]);

  return (
    <Chart
      chartType="Line"
      width="100%"
      height="400px"
      data={[['', ''], ...data]}
      options={options}
    />
  );
}
