import { useState, useEffect } from 'react';
import Api from '../Api';

const useFetch = (endpoint, params = {}) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    handleRequest();
  }, []);

  const handleRequest = async () => {
    setLoading(true);
    try {
      const response = await Api.get(endpoint, params, true);
      setData(response);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return { data, loading, error };
};

export default useFetch;
