import React, { useContext, useEffect, useState } from 'react';
import Card from './Card';
import {
  Button,
  HStack,
  Link,
  Skeleton,
  Spacer,
  Stack,
  Text,
  useClipboard,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import {
  CalendarIcon,
  CopyIcon,
  EditIcon,
  ExternalLinkIcon,
} from '@chakra-ui/icons';
import { LinkChart } from './LinkChart';
import { FiCornerDownRight } from 'react-icons/fi';
import { FaSignal, FaTrash } from 'react-icons/fa';
import { LinkModal } from './LinkModal';
import { LinkForm } from './LinkForm';
import Api from '../services/Api';
import moment from 'moment';
import LinkContext from '../context/NewLink';

function LinkMeta({ linkId = null }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [link, setLink] = useState({});
  const [linkHistory, setLinkHistory] = useState([]);
  const { setValue } = useClipboard();
  const { setUpdateLink } = useContext(LinkContext);
  const [loading, setLoading] = useState(true);

  const toast = useToast();

  async function handleLink(id) {
    setLoading(true);
    await fetchLinkMeta(id);
    await fetchLinkHistory(id);
    setLoading(false);
  }

  async function fetchLinkMeta(id) {
    try {
      const data = await Api.get(`/link/${id}`, {}, true);
      setLink(data);
    } catch (error) {
      toast({
        title: `Ooops algum problema aconteceu, tente novamente.`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  }

  async function fetchLinkHistory(id) {
    try {
      const data = await Api.get(`/link/referral-link/${id}`, {}, true);
      setLinkHistory(data);
    } catch (error) {
      toast({
        title: `Ooops algum problema aconteceu, tente novamente.`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  }

  async function deleteLink(id) {
    try {
      await Api.delete(`/link/${id}`, {}, true);
      toast({
        title: `Excluído com sucesso!`,
        status: 'success',
        position: 'top',
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: `Ooops algum problema aconteceu, tente novamente.`,
        status: 'error',
        position: 'top',
        isClosable: true,
      });
    }
  }

  useEffect(() => {
    if (linkId) {
      handleLink(linkId);
    }
  }, [linkId]);

  return (
    <div style={{ width: '100%' }}>
      <Card>
        {loading ? (
          <>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </>
        ) : (
          <>
            <HStack>
              <Text flex={2} as={'b'} fontSize="xl">
                {link?.description || `teml.ink/${link?.short}`}
              </Text>
              <LinkModal
                buttonText={'Editar'}
                modalHeaderText={'Editando'}
                leftIcon={<EditIcon />}
                variant={'outline'}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                onClick={() => {
                  setUpdateLink(link);
                  onOpen();
                }}
              >
                <LinkForm />
              </LinkModal>
              <Button
                onClick={() => deleteLink(link?._id)}
                colorScheme={'red'}
                leftIcon={<FaTrash />}
              >
                Excluir
              </Button>
            </HStack>
            <VStack flex={2} align={'stretch'} style={{ width: '100%' }}>
              <HStack justifyContent={'flex-start'}>
                <CalendarIcon boxSize={4} />
                <Text fontSize="lg">
                  {moment(link?.createdAt).format(
                    'ddd, MMM DD YYYY, h:mm A zz'
                  )}
                </Text>
              </HStack>
              <HStack justifyContent={'flex-start'}>
                <FaSignal boxSize={4} />
                <Text fontSize="lg">
                  <b>{link?.clicks}</b> cliques
                </Text>
              </HStack>
            </VStack>
          </>
        )}
      </Card>
      <Spacer />
      <Card>
        {loading ? (
          <>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </>
        ) : (
          <>
            <HStack justifyContent={'space-between'}>
              <Link href={`https://teml.ink/${link?.short}`} isExternal>
                <Text
                  color={'#2a5bd7'}
                  flex={2}
                  fontWeight="bold"
                  fontSize="xl"
                >
                  teml.ink/{link?.short} <ExternalLinkIcon mb="3px" />
                </Text>
              </Link>
              <LinkModal
                buttonText={'Copiar'}
                modalHeaderText={'Editando'}
                leftIcon={<CopyIcon />}
                variant={'outline'}
                isOpen={isOpen}
                onOpen={onOpen}
                onClose={onClose}
                onClick={() => setValue('ww')}
              >
                <LinkForm />
              </LinkModal>
            </HStack>
            <VStack flex={2} align={'stretch'} style={{ width: '100%' }}>
              <HStack justifyContent={'flex-start'}>
                <Text fontSize="lg">
                  <b>{link?.clicks}</b> cliques
                </Text>
              </HStack>
              <Link href={`https://teml.ink/${link?.short}`} isExternal>
                <HStack>
                  <FiCornerDownRight size={20} />
                  <Text fontSize="md">{link?.full}</Text>
                </HStack>
              </Link>
            </VStack>
          </>
        )}
      </Card>
      {loading ? (
        <Skeleton></Skeleton>
      ) : (
        <>
          {linkHistory?.length > 0 && (
            <Card>
              <VStack alignItems={'flex-start'}>
                <HStack>
                  <Text fontSize="lg">
                    <b>{link?.clicks}</b>
                  </Text>
                  <FaSignal />
                </HStack>
                <Text as="b">CLIQUES (últimos 7 dias)</Text>
              </VStack>
              <LinkChart linkHistory={linkHistory} />
            </Card>
          )}
        </>
      )}
    </div>
  );
}

export default LinkMeta;
