import React from 'react';
import Sidebar from '../components/Sidebar';
import Card from '../components/Card';
import Statistics from '../components/Statistics';
import Link from './Link';

const Dashboard = () => {
  return (
    <Sidebar>
      <Card>
        <Statistics />
      </Card>
      <Link />
    </Sidebar>
  );
};

export default Dashboard;
