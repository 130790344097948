import * as React from 'react';
import { Routes, Route } from 'react-router-dom';
import Dashboard from '../views/Dashboard';
import Login from '../views/Login';
import ApiKey from '../views/ApiKey';

function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/api-keys" element={<ApiKey />} />
      <Route path="/" element={<Dashboard />} />
    </Routes>
  );
}

export default Router;
