import React, { useEffect, useState } from 'react';
import useFetch from '../services/hooks/useFetch';
import {
  List,
  ListItem,
  Text,
  Flex,
  useClipboard,
  Box,
  Button,
  Spacer,
  useToast,
  Link,
  Tag,
} from '@chakra-ui/react';
import { ExternalLinkIcon, LinkIcon } from '@chakra-ui/icons';
import Card from './Card';
import { FiMousePointer } from 'react-icons/fi';

function Links({ setLinkId }) {
  const { data, loading } = useFetch('/link');
  const [link, setLink] = useState('');
  const { onCopy } = useClipboard(link);
  const toast = useToast();

  useEffect(() => {
    if (data?.length > 0) {
      setLinkId(data?.[0]?._id);
    }
  }, [data]);

  if (loading) return null;

  return (
    <Box h={'auto'} overflow={'auto'}>
      <List spacing={3}>
        {data?.map(({ short, source, full, clicks, description, _id }) => (
          <Card onClick={() => setLinkId(_id)}>
            <ListItem key={short}>
              <Flex
                flexWrap={'wrap'}
                dir="row"
                flexDir={'row'}
                justifyContent="center"
                alignItems="center"
              >
                <Box>
                  <Text
                    onClick={() => {
                      setLink(
                        `${process.env.REACT_APP_TEML_INK_PREFIX}/link/${short}`
                      );
                      onCopy();
                      toast({
                        title: `Link copiado!`,
                        status: 'success',
                        isClosable: true,
                      });
                    }}
                    cursor={'pointer'}
                    fontSize="xl"
                  >
                    <Flex flexDir={'row'}>
                      <LinkIcon mr={2} color="green.300" />
                      {description || `https://teml.ink/${short}`}
                      {source && source === 'api' && (
                        <Tag ml="2" size={'sm'} colorScheme="twitter">
                          criado via api
                        </Tag>
                      )}
                    </Flex>
                  </Text>
                  <Text ml={8} fontSize="md" color="green.500">
                    <Link href={full} isExternal>
                      {description ? `https://teml.ink/${short}` : full}
                      <ExternalLinkIcon mx="2px" />
                    </Link>
                  </Text>
                </Box>
                <Spacer />
                <Box>
                  <Button
                    justifySelf={'center'}
                    rightIcon={<FiMousePointer size={20} />}
                    color="purple.500"
                    variant="outline"
                  >
                    {clicks} clique{`${clicks >= 2 || clicks === 0 ? 's' : ''}`}
                  </Button>
                </Box>
              </Flex>
            </ListItem>
          </Card>
        ))}
      </List>
    </Box>
  );
}

export default Links;
