import firebase from 'firebase/compat/app';
import auth from 'firebase/compat/auth';

const config = {
  apiKey: 'AIzaSyBenefIgfwKp4vd7fEB05a7wm8PbwnGUUk',
  authDomain: 'encurtalinks.firebaseapp.com',
  projectId: 'encurtalinks',
  storageBucket: 'encurtalinks.appspot.com',
  messagingSenderId: '91661148963',
  appId: '1:91661148963:web:74088ba6203d6d5cc0e296',
};

let app = null;

if (!firebase.apps.length) {
  app = firebase.initializeApp(config);
} else {
  app = firebase.app();
}

const fbAuth = app.auth();

export { fbAuth, app, firebase };
