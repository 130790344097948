import {
  Box,
  Flex,
  Text,
  useColorModeValue,
  useBreakpointValue,
} from '@chakra-ui/react';

export default function LoginNav() {
  return (
    <Box>
      <Flex
        bg={useColorModeValue('white')}
        color={useColorModeValue('gray.600', 'white')}
        minH={'80px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}
      >
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'center' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'center' })}
            fontFamily="Lobster"
            fontSize={40}
            color={useColorModeValue('gray.800', 'white')}
          >
            encurtalinks
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
