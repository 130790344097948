import React from 'react';
import { ChakraProvider, theme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import Router from './router';
import { AuthProvider } from './context/Auth';
import { LinkProvider } from './context/NewLink';
import { install } from 'ga-gtag';

install('G-LXJM8PY8B5');

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <LinkProvider>
            <Router />
          </LinkProvider>
        </AuthProvider>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
